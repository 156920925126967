import { useStorage } from '@vueuse/core';
import { defineStore } from 'pinia';
import { useApiStore } from './useApiStore';

export const useChainStore = defineStore('chain-store', () => {
	const apiStore = useApiStore();

	const chainURLList = useStorage<ChainUrlList>('c_u_l', [
		{
			label: 'main',
			url: 'wss://explorer.unitchain.io/',
		},
		{
			label: 'localDefault',
			url: 'ws://localhost:9944/',
		},
	]);

	const currentChainUrl = useStorage('c_c_u_l', chainURLList.value[0]);

	function addChainURL(label: string, url: string) {
		chainURLList.value.push({
			label,
			url,
		});
	}

	function setChainURL(chain: ChainUrl): void {
		currentChainUrl.value = chain;

		apiStore.connectAPI(currentChainUrl.value.url);
	}

	function removeChainURL(id: number): void;
	function removeChainURL(label: string): void;
	function removeChainURL(idOrLabel: number | string): void {
		if (typeof idOrLabel === 'number') {
			chainURLList.value.splice(idOrLabel, 1);
		}

		if (typeof idOrLabel === 'string') {
			chainURLList.value = chainURLList.value.filter((item) => item.label !== idOrLabel);
		}

		if (chainURLList.value.length === 0) {
			currentChainUrl.value = undefined;
		}

		currentChainUrl.value = chainURLList.value[0];
	}

	return {
		currentChainUrl,
		chainURLList,
		addChainURL,
		setChainURL,
		removeChainURL,
	};
});
