import { useStorage } from '@vueuse/core';
import { defineStore } from 'pinia';
import { computed, ref } from 'vue';
import { useAccountStore } from '@/stores';

export const useAppStore = defineStore('app-store', () => {
	const accountsStore = useAccountStore();

	const isRPCListExpanded = ref<boolean>(true);
	const isConsoleExpanded = ref<boolean>(true);

	const tokenAdressess = useStorage<string[]>('t_a', []);
	const capsuleAdressess = useStorage<string[]>('c_a', []);

	const allAssetsAddrs = computed(() => {
		return [...tokenAdressess.value, ...capsuleAdressess.value];
	});

	/**
	 * Выставляет список адресов токенов по шаблонной строке `"addr1,addr2,addr3"`
	 */
	function setTokenAdressess(addressesRaw: string) {
		const addrs = addressesRaw.split(',');
		const newList = addrs.filter((addr) => !!addr).map((addr) => addr.trim());

		const hasLengthChanged = newList.length !== tokenAdressess.value.length;
		const hasContentChanged = newList.some((addr) => !tokenAdressess.value.includes(addr));

		if (hasLengthChanged || hasContentChanged) accountsStore.updateAccounts();

		tokenAdressess.value = addrs.filter((addr) => !!addr).map((addr) => addr.trim());
	}

	/**
	 * Выставляет список адресов капсул по шаблонной строке `"addr1,addr2,addr3"`
	 */
	function setCapsuleAdressess(addressesRaw: string) {
		const addrs = addressesRaw.split(',');
		const newList = addrs.filter((addr) => !!addr).map((addr) => addr.trim());

		const hasLengthChanged = newList.length !== capsuleAdressess.value.length;
		const hasContentChanged = newList.some((addr) => !capsuleAdressess.value.includes(addr));

		if (hasLengthChanged || hasContentChanged) accountsStore.updateAccounts();

		capsuleAdressess.value = addrs.filter((addr) => !!addr).map((addr) => addr.trim());
	}

	return {
		isRPCListExpanded,
		isConsoleExpanded,
		tokenAdressess,
		capsuleAdressess,
		allAssetsAddrs,
		setTokenAdressess,
		setCapsuleAdressess,
	};
});
