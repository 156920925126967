import { createRouter, createWebHistory, type RouteRecordRaw } from 'vue-router';
import { launchMiddlewareSystem } from './middleware-system';
import type { Component } from 'vue';

declare module 'vue-router' {
	interface RouteMeta {
		layoutName?: string;
		layoutComponent?: Component;
		middleware?: Middleware | Middleware[];
	}
}

const routes: RouteRecordRaw[] = [
	{
		path: '/',
		name: 'index',
		component: () => import('@/views/index.vue'),
	},
	{
		path: '/wallets',
		meta: {
			layoutName: 'sidebar',
		},
		component: () => import('@/containers/PageRouter.vue'),
		children: [
			{
				path: '',
				name: 'wallets',
				component: () => import('@/views/wallets.vue'),
			},
			{
				path: ':address',
				component: () => import('@/containers/PageRouter.vue'),
				children: [
					{
						path: '',
						name: 'wallet',
						meta: {
							layoutName: 'wallet',
						},
						component: () => import('@/views/wallet.vue'),
					},
					{
						path: 'transfer',
						name: 'transfer',
						meta: {
							layoutName: 'wallet',
						},
						component: () => import('@/views/transfer.vue'),
					},
					{
						path: 'allowance',
						name: 'allowance',
						meta: {
							layoutName: 'wallet',
						},
						component: () => import('@/views/allowance.vue'),
					},
					{
						path: 'transfer-from',
						name: 'transfer-from',
						meta: {
							layoutName: 'wallet',
						},
						component: () => import('@/views/transfer-from.vue'),
					},
				],
			},
		],
	},
];

const router = createRouter({
	history: createWebHistory(),
	routes,
});

router.beforeResolve(launchMiddlewareSystem);

export default router;
