import { Currency } from '@/common/enums';
import { useStorage } from '@vueuse/core';
import { defineStore } from 'pinia';
import { ref } from 'vue';
import { useAccountStore } from '@/stores';

export const useCurrencyStore = defineStore('currency-store', () => {
	const accounstStore = useAccountStore();

	const currentCurrency = useStorage<TypeCurrency>('c_c', 'USD');
	const currencyList = ref(Object.keys(Currency) as TypeCurrency[]);

	function setCurrency(selectedCurrency: TypeCurrency): void {
		currentCurrency.value = selectedCurrency;

		accounstStore.updateAccounts();
	}

	return {
		currentCurrency,
		currencyList,
		setCurrency,
	};
});
