import { ACCOUNT_NATIVE_TOKEN_ADDRESS } from '@/common/consts';
import Contract from '@/common/contracts/types/contracts/capsule';
import { useTokenConverters } from '@/hooks';
import { useAccountStore, useApiStore, useConsoleStore } from '@/stores';
import type { ApiPromise } from '@polkadot/api';
import type BigN from 'big.js';

export const useAccountAllowanceExtend = () => {
	const consoleStore = useConsoleStore();
	const apiStore = useApiStore();
	const tokenConverters = useTokenConverters();
	const accountStore = useAccountStore();

	async function getAllowanceInfo(signer: ExtendedKeyringPair, tokenAddr: string) {
		try {
			if (!apiStore.api) return;

			const contract = new Contract(tokenAddr, signer, apiStore.api as ApiPromise);

			const getAssetType = contract.query.contractType();
			const getAllowanceInfoList = contract.query.allowances(signer.address);
			const getTokenName = contract.query.tokenName();
			const getTokenDecimals = contract.query.tokenDecimals();

			const [assetTypeResponse, allowanceInfoRes, tokenNameRes, tokenDecimalsRes] =
				await Promise.all([
					getAssetType,
					getAllowanceInfoList,
					getTokenName,
					getTokenDecimals,
				]);

			if (
				!allowanceInfoRes.value?.ok ||
				!tokenNameRes.value?.ok ||
				tokenDecimalsRes.value?.ok === undefined
			)
				return;

			const tokenType = assetTypeResponse.value?.ok ?? '';
			const tokenName = tokenNameRes.value.ok;
			const decimals = tokenDecimalsRes.value.ok;
			const allowanceInfoList: [string, BigN][] = allowanceInfoRes.value.ok.map(
				(allowanceInfo) => {
					const [accId, amount] = allowanceInfo;
					const amountByDecimals = tokenConverters.convertNativeToDecimals(
						amount.rawNumber,
						decimals,
					);

					return [accId.toString(), amountByDecimals];
				},
			);

			return {
				tokenType: accountStore.getAssetTypeLabel(tokenType),
				tokenAddr,
				tokenName,
				allowanceInfoList,
			};
		} catch (err) {
			const errMsg = err instanceof Error ? err.message : 'Неизвестная ошибка';

			consoleStore.addConsoleMessage({
				type: 'error',
				desc: 'Получена ошибка при попытке выполнить запрос на получение списка allowances',
				params: [
					['Contract address', tokenAddr],
					['Signer address', signer.address],
				],
				error: errMsg,
			});
		}

		return;
	}

	async function createApproveTransaction(
		signer: ExtendedKeyringPair,
		toAddress: string,
		amountInDecimals: BigN,
		token: ExtendedKeyringPairAsset,
	): Promise<CreateTransactionReturn | undefined> {
		if (!apiStore.api) return;

		const amount = tokenConverters.convertDecimalsToNative(
			amountInDecimals,
			token?.decimals ?? 0,
		);

		try {
			const isNativeToken = token.address === ACCOUNT_NATIVE_TOKEN_ADDRESS;

			if (isNativeToken) throw new Error('AllowToUse нельзя применять для нативных токенов');

			const contract = new Contract(token.address, signer, apiStore.api as ApiPromise);

			const { gasRequired } = await contract.query.approve(toAddress, amount, {});

			const instance = contract.buildExtrinsic.approve(toAddress, amount, {
				gasLimit: gasRequired,
			});

			const dispatchInfo = await instance.paymentInfo(signer);

			const metaInfo = JSON.stringify(
				{
					...(instance.toHuman() as object),
					...dispatchInfo.toHuman(),
				},
				null,
				4,
			);

			return {
				instance,
				dispatchInfo,
				metaInfo,
				signAndSend: async () => {
					if (signer.isLocked) signer.unlock();

					const unsubTx = await instance.signAndSend(signer, (result) => {
						if (result.status.isInBlock) {
							consoleStore.addConsoleMessage({
								desc: 'Создана транзакция approve',
								params: [
									['Signer address', signer.address],
									['Target address', toAddress],
									['Token name', token.name],
									['Token amount', amountInDecimals.toString()],
									['In block', result.status.asInBlock.toString()],
									['Tx hash', result.txHash.toString()],
								],
							});

							return;
						}

						if (result.status.isFinalized && result.dispatchError) {
							let error: string;

							if (result.dispatchError.isModule) {
								const decoded = apiStore.api?.registry.findMetaError(
									result.dispatchError.asModule,
								);

								error = decoded?.docs.toString() ?? 'Неизвестная ошибка';
							} else {
								error = result.dispatchError.toString();
							}

							consoleStore.addConsoleMessage({
								type: 'error',
								desc: 'Транзакция approve завершилась с ошибкой',
								params: [
									['Signer address', signer.address],
									['Target address', toAddress],
									['Token name', token.name],
									['Token amount', amountInDecimals.toString()],
									['In block', result.status.asFinalized.toString()],
									['Tx hash', result.txHash.toString()],
								],
								error: error,
							});

							unsubTx();

							return;
						}

						if (result.status.isFinalized) {
							consoleStore.addConsoleMessage({
								type: 'success',
								desc: 'Транзакция approve успешно завершена',
								params: [
									['Signer address', signer.address],
									['Target address', toAddress],
									['Token name', token.name],
									['Token amount', amountInDecimals.toString()],
									['In block', result.status.asFinalized.toString()],
									['Tx hash', result.txHash.toString()],
								],
							});

							accountStore.updateAccounts();
							unsubTx();

							return;
						}
					});
				},
			};
		} catch (err) {
			const errMsg = err instanceof Error ? err.message : 'Неизвестная ошибка';

			consoleStore.addConsoleMessage({
				type: 'error',
				desc: 'Ошибка при попытке выполнить запрос approve',
				params: [
					['Contract address', token.address],
					['Signer address', signer.address],
					['To address', toAddress],
					['Token name', token.name],
					['Token amount', amountInDecimals.toString()],
				],
				error: errMsg,
			});
		}

		return;
	}

	return {
		getAllowanceInfo,
		createApproveTransaction,
	};
};
