import keyring from '@polkadot/ui-keyring';

export function generateAccountName() {
	const prefix = 'Account';
	const accountCount = keyring.getAccounts().length;

	return `${prefix}_${accountCount}`;
}

export function validateAddress(address: string) {
	try {
		validateAddress(address);

		return true;
	} catch (error) {
		return false;
	}
}
