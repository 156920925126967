import { ACCOUNT_NATIVE_TOKEN_ADDRESS } from '@/common/consts';
import Contract from '@/common/contracts/types/contracts/capsule';
import { useTokenConverters } from '@/hooks';
import { useAccountStore, useApiStore, useAppStore, useCurrencyStore } from '@/stores';
import type { ApiPromise } from '@polkadot/api';
import { BN } from 'bn.js';

export const useAccountAssetsDataExtend = () => {
	const apiStore = useApiStore();
	const tokenConverters = useTokenConverters();
	const appStore = useAppStore();
	const currencyStore = useCurrencyStore();
	const accountStore = useAccountStore();

	async function updateAccountNativeTokenData(account: ExtendedKeyringPair) {
		if (!apiStore.api) return;

		const info = apiStore.api.registry.getChainProperties();

		if (!info) return;

		const {
			data: { free },
		} = await apiStore.api.query.system.account(account.address);

		const name = info?.tokenSymbol.value.toLocaleString();
		const decimals = parseInt(info?.tokenDecimals.value.toLocaleString());
		const balanceNative = new BN(free.toString());
		const balanceByDecimals = tokenConverters.convertNativeToDecimals(balanceNative, decimals);
		const balanceByFiat = tokenConverters.convertNativeToFiat(name, balanceNative, decimals);

		if (!account?.assets) {
			account.assets = {};
		}

		if (!account.assets.tokens) {
			account.assets.tokens = {} as ExtendedKeyringPairTokens;
		}

		account.assets.tokens.native = {
			type: accountStore.getAssetTypeLabel('native'),
			address: ACCOUNT_NATIVE_TOKEN_ADDRESS,
			name,
			balanceNative,
			balanceByFiat,
			decimals,
			balanceByDecimals,
		};
	}

	async function updateAccountTokensData(account: ExtendedKeyringPair) {
		const tokens: ExtendedKeyringPairAsset[] = [];

		for (const addr of appStore.tokenAdressess) {
			const contract = new Contract(addr, account, apiStore.api as ApiPromise);

			const getAssetType = contract.query.contractType();
			const getBalance = contract.query.balanceOf(account.address);
			const getTokenName = contract.query.tokenName();
			const getTokenDecimals = contract.query.tokenDecimals();

			const [assetTypeResponse, balanceResponse, tokenNameResponse, tokenDecimalsResponse] =
				await Promise.all([getAssetType, getBalance, getTokenName, getTokenDecimals]);

			if (
				!balanceResponse.value.ok ||
				!tokenNameResponse.value.ok ||
				tokenDecimalsResponse.value.ok === undefined
			)
				return;

			const type = assetTypeResponse.value?.ok ?? '';
			const tokenName = tokenNameResponse.value.ok;
			const balanceNative = new BN(balanceResponse.value.ok.toString());
			const decimals = tokenDecimalsResponse.value.ok;
			const balanceByDecimals = tokenConverters.convertNativeToDecimals(
				balanceNative,
				decimals,
			);
			const balanceByFiat = tokenConverters.convertDecimalsToFiat(
				tokenName,
				balanceByDecimals,
				currencyStore.currentCurrency,
			);

			tokens.push({
				type: accountStore.getAssetTypeLabel(type),
				address: addr,
				name: tokenName,
				balanceNative,
				balanceByDecimals,
				balanceByFiat,
				decimals,
			});
		}

		if (!account?.assets) {
			account.assets = {};
		}

		if (!account.assets.tokens) {
			account.assets.tokens = {} as ExtendedKeyringPairTokens;
		}

		account.assets.tokens.others = tokens;
	}

	async function updateAccountCapsulesData(account: ExtendedKeyringPair) {
		const capsules: ExtendedKeyringPairAsset[] = [];

		for (const addr of appStore.capsuleAdressess) {
			const contract = new Contract(addr, account, apiStore.api as ApiPromise);

			const getAssetType = contract.query.contractType();
			const getBalance = contract.query.balanceOf(account.address);
			const getTokenName = contract.query.tokenName();
			const getTokenDecimals = contract.query.tokenDecimals();

			const [assetTypeResponse, balanceResponse, tokenNameResponse, tokenDecimalsResponse] =
				await Promise.all([getAssetType, getBalance, getTokenName, getTokenDecimals]);

			if (
				!balanceResponse.value?.ok ||
				!tokenNameResponse.value?.ok ||
				tokenDecimalsResponse.value?.ok === undefined
			)
				return;

			const type = assetTypeResponse.value?.ok ?? '';
			const tokenName = tokenNameResponse.value.ok;
			const balanceNative = new BN(balanceResponse.value.ok.toString());
			const decimals = tokenDecimalsResponse.value.ok;
			const balanceByDecimals = tokenConverters.convertNativeToDecimals(
				balanceNative,
				decimals,
			);
			const balanceByFiat = tokenConverters.convertDecimalsToFiat(
				tokenName,
				balanceByDecimals,
				currencyStore.currentCurrency,
			);

			capsules.push({
				type: accountStore.getAssetTypeLabel(type),
				address: addr,
				name: tokenName,
				balanceNative,
				balanceByDecimals,
				balanceByFiat,
				decimals,
			});
		}

		if (!account?.assets) {
			account.assets = {};
		}

		account.assets.capsules = capsules;
	}

	return {
		updateAccountNativeTokenData,
		updateAccountTokensData,
		updateAccountCapsulesData,
	};
};
