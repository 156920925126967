import BN from 'bn.js';
import BigN from 'big.js';

export const useTokenConverters = () => {
	// TODO: данный обьект необходимо заполнять и периодически обновлять запросом
	const tokenCurrency: TokenCurrency = {
		BYN: {
			USDT: 1,
			UNT: 1,
			BYN: 1,
		},
		USD: {
			USDT: 1,
			UNT: 1,
			BYN: 1,
		},
	};

	/**
	 * Конвертация нативного значения токена в значение с decimals
	 */
	function convertNativeToDecimals(nativeValue: BN, tokenDecimals: number): BigN {
		const nativeValueInBigN = new BigN(nativeValue.toString());
		const divisor = Math.pow(10, tokenDecimals);

		return nativeValueInBigN.div(divisor);
	}

	/**
	 * Конвертация значения токена с decimals в нативное
	 */
	function convertDecimalsToNative(decimalsValue: BigN, tokenDecimals: number): BN {
		const mult = decimalsValue.times(Math.pow(10, tokenDecimals));
		const decimalsInBN = new BN(mult.toFixed(0).toString());

		return decimalsInBN;
	}

	/**
	 * Конвертация значения токена с decimals в fiat
	 */
	function convertDecimalsToFiat(
		tokenName: string,
		decimalsValue: BigN,
		fiatName: CurrencyName = 'USD',
	): BigN {
		const fiat = tokenCurrency[fiatName][tokenName];

		if (!fiat) return new BigN(-1);

		return decimalsValue.times(fiat);
	}

	/**
	 * Конвертация значения токена в fiat в значение с decimals
	 */
	function convertFiatToDecimals(
		tokenName: string,
		fiatValue: BigN,
		fiatName: CurrencyName = 'USD',
	): BigN {
		const fiat = tokenCurrency[fiatName][tokenName];

		if (!fiat) return new BigN(-1);

		return fiatValue.div(fiat);
	}

	/**
	 * Конвертация нативного значения токена в fiat
	 */
	function convertNativeToFiat(
		tokenName: string,
		nativeValue: BN,
		tokenDecimals: number,
		fiatName: CurrencyName = 'USD',
	): BigN {
		const decimals = convertNativeToDecimals(nativeValue, tokenDecimals);
		const fiat = convertDecimalsToFiat(tokenName, decimals, fiatName);

		if (fiat.lt(0)) return new BigN(-1);

		return fiat;
	}

	/**
	 * Конвертация значения токена в fiat в нативное
	 */
	function convertFiatToNative(
		tokenName: string,
		fiatValue: BigN,
		tokenDecimals: number,
		fiatName: CurrencyName = 'USD',
	): BN {
		const decimals = convertFiatToDecimals(tokenName, fiatValue, fiatName);
		const native = convertDecimalsToNative(decimals, tokenDecimals);

		if (native.lt(new BN(0))) return new BN(-1);

		return native;
	}

	return {
		convertNativeToDecimals,
		convertDecimalsToNative,
		convertDecimalsToFiat,
		convertFiatToDecimals,
		convertNativeToFiat,
		convertFiatToNative,
	};
};
