import { defineStore } from 'pinia';
import { ref } from 'vue';

type ConsoleMessagePartial = Omit<ConsoleMessage, 'type'> & Partial<Pick<ConsoleMessage, 'type'>>;

export const useConsoleStore = defineStore('console-store', () => {
	const consoleMessages = ref<ConsoleMessage[]>([]);

	function addConsoleMessage(msg: ConsoleMessagePartial) {
		if (!msg?.type || msg?.type === undefined) msg.type = 'info';

		consoleMessages.value.push(msg as ConsoleMessage);
	}

	return {
		consoleMessages,
		addConsoleMessage,
	};
});
