import { generateAccountName } from '@/helpers';
import keyring from '@polkadot/ui-keyring';
import { defineStore } from 'pinia';
import { ref } from 'vue';
import { mnemonicGenerate } from '@polkadot/util-crypto/mnemonic/generate';
import { mnemonicValidate } from '@polkadot/util-crypto/mnemonic/validate';
import { useIntervalFn, useStorage } from '@vueuse/core';
import {
	ACCOUNT_MNEMONIC_WORD_COUNT,
	ACCOUNT_UPDATE_TIME,
	GODWALLET_ACCOUNT_NAME,
	GODWALLET_MNEMONIC,
} from '@/common/consts';
import { useAppStore, useConsoleStore } from '@/stores';
import { useAccountAssetsDataExtend } from './account-assets-data.extend';
import { useAccountTransferExtend } from './account-transfer.extend';
import { useAccountAllowanceExtend } from './account-allowance.extend';
import { useAccountTotalsExtend } from './account-totals.extend';

interface MnemonicStorage {
	address: string;
	mnemonic: string;
}

export const useAccountStore = defineStore('account-store', () => {
	const consoleStore = useConsoleStore();
	const appStore = useAppStore();

	const accountAssetsDataExtend = useAccountAssetsDataExtend();
	const accountTransferExtend = useAccountTransferExtend();
	const accountAllowanceExtend = useAccountAllowanceExtend();
	const accountTotalsExtend = useAccountTotalsExtend();
	const accountTransferFromExtend = useAccountTransferExtend();

	const accounts = ref<ExtendedKeyringPair[]>(getAccounts());
	const mnemonics = useStorage<MnemonicStorage[]>('a_m', []);

	const subscribeToUpdateAccountsAssetsData = useIntervalFn(
		() => updateAccounts(['skipGodWallet']),
		ACCOUNT_UPDATE_TIME,
		{
			immediate: false,
			immediateCallback: true,
		},
	);

	function createAccount(accountName?: string) {
		const mnemonic = mnemonicGenerate(ACCOUNT_MNEMONIC_WORD_COUNT);

		if (!accountName) {
			accountName = generateAccountName();
		}

		const { pair } = keyring.addUri(mnemonic, undefined, { name: accountName });

		accounts.value.push(pair);
		mnemonics.value.push({
			address: pair.address,
			mnemonic,
		});

		consoleStore.addConsoleMessage({
			type: 'success',
			desc: 'Создание аккаунта успешно завершено',
			params: [
				['Account address', pair.address],
				['Account name', accountName],
				['Mnemonic', mnemonic],
			],
		});

		return pair;
	}

	function importAccount(mnemonic: string, accountName?: string) {
		const isMnemonicValid = mnemonicValidate(mnemonic);
		const isGodWallet = mnemonic === GODWALLET_MNEMONIC;

		if (!isMnemonicValid && !isGodWallet) {
			consoleStore.addConsoleMessage({
				type: 'error',
				desc: 'При попытке импортировать аккаунт произошла ошибка',
				params: [
					['Account name', accountName ?? 'Неизвестно'],
					['Mnemonic', mnemonic],
				],
				error: 'Некорректный mnemonic',
			});

			return;
		}

		if (!accountName) {
			accountName = generateAccountName();
		}

		const { pair } = keyring.addUri(mnemonic, undefined, { name: accountName });

		accounts.value.push(pair);
		mnemonics.value.push({
			address: pair.address,
			mnemonic,
		});

		consoleStore.addConsoleMessage({
			type: 'success',
			desc: 'Аккаунт успешно импортирован',
			params: [
				['Account name', accountName],
				['Mnemonic', mnemonic],
			],
		});

		return pair;
	}

	function clearAccounts() {
		const keyringAccounts = keyring.getAccounts();

		keyringAccounts.forEach((acc) => {
			keyring.forgetAccount(acc.address);
		});

		accounts.value = [];
		mnemonics.value = [];
	}

	function clearAccount(address: string) {
		keyring.forgetAccount(address);

		accounts.value = accounts.value.filter((acc) => acc.address !== address);
		mnemonics.value = mnemonics.value.filter((mnemonic) => mnemonic.address !== address);
	}

	function setAccountName(address: string, name: string) {
		const account = accounts.value.filter((account) => account.address === address)[0];

		if (account) {
			account.setMeta({ name });
			keyring.saveAccountMeta(account, account.meta);
		}
	}

	function getAccounts() {
		return keyring.getPairs().sort((prev, next) => {
			if (prev.meta?.whenCreated && next.meta?.whenCreated) {
				return prev.meta?.whenCreated - next.meta?.whenCreated;
			} else {
				return -1;
			}
		});
	}

	function findAccount(targetAddress: string) {
		return accounts.value.filter((account) => account.address === targetAddress)[0];
	}

	function findMnemonic(targetAddress: string) {
		return mnemonics.value.filter((mnemonic) => mnemonic.address === targetAddress)[0]
			?.mnemonic;
	}

	function getAssetTypeLabel(maybeType: string): ExtendedKeyringPairAssetType {
		const labelType: Record<string, ExtendedKeyringPairAssetType> = {
			native: 'Native',
			capsule: 'CAPSULE',
		};

		return labelType[maybeType] ?? 'Unknown';
	}

	async function getAllowanceInfoList(
		signer: ExtendedKeyringPair,
	): Promise<(AllowanceInfo | undefined)[]> {
		const assetsAddrs = appStore.allAssetsAddrs;
		const promiseArr = [];

		for (const addr of assetsAddrs) {
			promiseArr.push(accountAllowanceExtend.getAllowanceInfo(signer, addr));
		}

		const allowanceInfoList = await Promise.all(promiseArr);

		return allowanceInfoList;
	}

	async function updateAccounts(filters: Array<'skipGodWallet' | 'onlyGodWallet'> = []) {
		try {
			for (const account of accounts.value) {
				if (
					filters.includes('skipGodWallet') &&
					account.meta.name === GODWALLET_ACCOUNT_NAME
				)
					continue;
				if (
					filters.includes('onlyGodWallet') &&
					account.meta.name !== GODWALLET_ACCOUNT_NAME
				)
					continue;

				await Promise.all([
					accountAssetsDataExtend.updateAccountTokensData(account),
					accountAssetsDataExtend.updateAccountCapsulesData(account),
					accountAssetsDataExtend.updateAccountNativeTokenData(account),
				]);
			}
		} catch (err) {
			const errMsg = err instanceof Error ? err.message : 'Неизвестная ошибка';

			consoleStore.addConsoleMessage({
				type: 'error',
				desc: 'При попытке обновить данные аккаунтов произошла ошибка',
				error: errMsg,
			});
		}
	}

	return {
		accounts,
		subscribeToUpdateAccountsAssetsData,
		createAccount,
		importAccount,
		clearAccounts,
		clearAccount,
		setAccountName,
		getAccounts,
		findAccount,
		findMnemonic,
		getAssetTypeLabel,
		getAllowanceInfoList,
		updateAccounts,
		...accountAssetsDataExtend,
		...accountTransferExtend,
		...accountAllowanceExtend,
		...accountTotalsExtend,
		...accountTransferFromExtend,
	};
});
