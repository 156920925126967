import { createApp } from 'vue';
import { createPinia } from 'pinia';
import App from '@/App.vue';
import router from '@/router/router';
import { initStartupFunctions } from '@/helpers';

const app = createApp(App);

(async () => {
	app.use(createPinia());

	await initStartupFunctions();

	app.use(router);

	app.mount('#app');
})();
