import { useAccountStore, useApiStore, useConsoleStore } from '@/stores';
import { useTokenConverters } from '@/hooks';
import { ACCOUNT_NATIVE_TOKEN_ADDRESS } from '@/common/consts';
import type { RuntimeDispatchInfo } from '@polkadot/types/interfaces';
import type { ApiPromise } from '@polkadot/api';
import Contract from '@/common/contracts/types/contracts/capsule';
import type BigN from 'big.js';

export const useAccountTransferExtend = () => {
	const apiStore = useApiStore();
	const consoleStore = useConsoleStore();
	const tokenConverters = useTokenConverters();
	const accountStore = useAccountStore();

	async function createTransferTokenToAccount(
		signer: ExtendedKeyringPair,
		toAddress: string,
		token: ExtendedKeyringPairAsset,
		amountInDecimals: BigN,
	): Promise<CreateTransactionReturn | undefined> {
		if (!apiStore.api) return;

		const amount = tokenConverters.convertDecimalsToNative(
			amountInDecimals,
			token?.decimals ?? 0,
		);

		const isNativeToken = token.address === ACCOUNT_NATIVE_TOKEN_ADDRESS;

		let transfer: CreateTransactionInstance;

		if (isNativeToken) {
			transfer = apiStore.api.tx.balances.transferAllowDeath(toAddress, amount);
		} else {
			const contract = new Contract(token.address, signer, apiStore.api as ApiPromise);

			const args = [toAddress, amount] as const;

			const { gasRequired } = await contract.query.transfer(...args, ...[[], {}]);

			transfer = contract.buildExtrinsic.transfer(
				...args,
				...[
					[],
					{
						gasLimit: gasRequired,
					},
				],
			);
		}

		const transferInfo: RuntimeDispatchInfo = await transfer.paymentInfo(signer);

		const transferMetaInfo = JSON.stringify(
			{
				...(transfer.toHuman() as object),
				...transferInfo.toHuman(),
			},
			null,
			4,
		);

		return {
			instance: transfer,
			dispatchInfo: transferInfo,
			metaInfo: transferMetaInfo,
			signAndSend: async () => {
				if (signer.isLocked) signer.unlock();

				const unsubTx = await transfer.signAndSend(signer, (result) => {
					if (result.status.isInBlock) {
						consoleStore.addConsoleMessage({
							desc: 'Создана и добавлена транзакция transfer',
							params: [
								['Signer address', signer.address],
								['Target address', toAddress],
								['Token name', token.name],
								['Token amount', amountInDecimals.toString()],
								['In block', result.status.asInBlock.toString()],
								['Tx hash', result.txHash.toString()],
							],
						});

						return;
					}

					if (result.status.isFinalized && result.dispatchError) {
						let error: string;

						if (result.dispatchError.isModule) {
							const decoded = apiStore.api?.registry.findMetaError(
								result.dispatchError.asModule,
							);

							error = decoded?.docs.toString() ?? 'Неизвестная ошибка';
						} else {
							error = result.dispatchError.toString();
						}

						consoleStore.addConsoleMessage({
							type: 'error',
							desc: 'Транзакция transfer завершилась с ошибкой',
							params: [
								['Signer address', signer.address],
								['Target address', toAddress],
								['Token name', token.name],
								['Token amount', amountInDecimals.toString()],
								['In block', result.status.asFinalized.toString()],
								['Tx hash', result.txHash.toString()],
							],
							error: error,
						});
						unsubTx();

						return;
					}

					if (result.status.isFinalized) {
						consoleStore.addConsoleMessage({
							type: 'success',
							desc: 'Транзакция transfer успешно завершена',
							params: [
								['Signer address', signer.address],
								['Target address', toAddress],
								['Token name', token.name],
								['Token amount', amountInDecimals.toString()],
								['In block', result.status.asFinalized.toString()],
								['Tx hash', result.txHash.toString()],
							],
						});

						accountStore.updateAccounts();
						unsubTx();

						return;
					}
				});
			},
		};
	}

	return {
		createTransferTokenToAccount,
	};
};
