import { ApiPromise, WsProvider } from '@polkadot/api';
import { defineStore } from 'pinia';
import { ref } from 'vue';
import { useChainStore, useConsoleStore, useGodWalletStore } from '@/stores';

import '@/common/polkadot';

export const useApiStore = defineStore('api-store', () => {
	const consoleStore = useConsoleStore();
	const chainStore = useChainStore();
	const godWalletStore = useGodWalletStore();

	const api = ref<ApiPromise | undefined>(undefined);
	const isConnecting = ref(false);

	async function connectAPI(apiUrl?: string) {
		isConnecting.value = true;

		if (!apiUrl) {
			apiUrl = chainStore.currentChainUrl?.url ?? '???';
		}

		consoleStore.addConsoleMessage({
			desc: 'Попытка подключения...',
			params: [['Target API URL', apiUrl]],
		});

		try {
			if (!apiUrl) {
				throw new Error('Не выбрана сеть.');
			}

			const wsProvider = new WsProvider(apiUrl);
			api.value = await ApiPromise.create({
				provider: wsProvider,
				throwOnConnect: true,
				noInitWarn: true,
			});

			await api.value.isReady;

			godWalletStore.subscribeToUpdateGodWalletAssets.resume();

			consoleStore.addConsoleMessage({
				type: 'success',
				desc: 'Подключение успешно выполнено',
				params: [['API URL', apiUrl]],
			});
		} catch (err) {
			const errMsg = err instanceof Error ? err.message : 'Неизвестная ошибка';

			consoleStore.addConsoleMessage({
				type: 'error',
				desc: 'Ошибка при попытке создать подключение',
				params: [['Target API URL', apiUrl]],
				error: errMsg,
			});
		} finally {
			isConnecting.value = false;
		}
	}

	return {
		api,
		isConnecting,
		connectAPI,
	};
});
