import BigN from 'big.js';

export const useAccountTotalsExtend = () => {
	function getAccountTotalAssetsByFiat(
		account: ExtendedKeyringPair,
		filter: AccountAssetsFilter = ['all'],
	): BigN {
		const total: BigN = new BigN(0);

		if (filter.includes('all') || filter.includes('native')) {
			total.add(account?.assets?.tokens?.native?.balanceByFiat ?? 0);
		}

		if (filter.includes('all') || filter.includes('other')) {
			const otherReduce = account?.assets?.tokens?.others?.reduce(
				(acc, token) => acc.add(token.balanceByFiat),
				new BigN(0),
			);

			total.add(otherReduce ?? new BigN(0));
		}

		if (filter.includes('all') || filter.includes('capsule')) {
			const capsuleReduce = account?.assets?.capsules?.reduce(
				(acc, capsule) => acc.add(capsule.balanceByFiat),
				new BigN(0),
			);

			total.add(capsuleReduce ?? new BigN(0));
		}

		return total;
	}

	function getAccountAssetsByArray(
		account: ExtendedKeyringPair,
		filter: AccountAssetsFilter = ['all'],
	): ExtendedKeyringPairAsset[] {
		const arr: ExtendedKeyringPairAsset[] = [];

		if (filter.includes('all') || filter.includes('native')) {
			const nativeTokenAsArr = account?.assets?.tokens?.native
				? [account?.assets?.tokens?.native]
				: [];
			arr.push(...nativeTokenAsArr);
		}

		if (filter.includes('all') || filter.includes('other')) {
			const tokensAsArr = account?.assets?.tokens?.others ?? [];
			arr.push(...tokensAsArr);
		}

		if (filter.includes('all') || filter.includes('capsule')) {
			const capsulesAsArr = account?.assets?.capsules ?? [];
			arr.push(...capsulesAsArr);
		}

		return arr;
	}

	return {
		getAccountTotalAssetsByFiat,
		getAccountAssetsByArray,
	};
};
