import keyring from '@polkadot/ui-keyring';
import { cryptoWaitReady } from '@polkadot/util-crypto';

export async function initKeyring() {
	await cryptoWaitReady();

	keyring.loadAll({
		ss58Format: 42,
		type: 'sr25519',
	});
}
