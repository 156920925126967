import type { ContractPromise } from '@polkadot/api-contract';
import type { ApiPromise } from '@polkadot/api';

export default class EventsClass {
	readonly __nativeContract: ContractPromise;
	readonly __api: ApiPromise;

	constructor(nativeContract: ContractPromise, api: ApiPromise) {
		this.__nativeContract = nativeContract;
		this.__api = api;
	}
}
