import { defineStore } from 'pinia';
import { useAccountStore } from '@/stores';
import { ref } from 'vue';
import { useIntervalFn } from '@vueuse/core';
import { GODWALLET_ACCOUNT_NAME, GODWALLET_MNEMONIC, ACCOUNT_UPDATE_TIME } from '@/common/consts';
import keyring from '@polkadot/ui-keyring';

export const useGodWalletStore = defineStore('god-wallet-store', () => {
	const accountStore = useAccountStore();

	const accountGodWallet = ref<ExtendedKeyringPair | undefined>(getAccountGodWallet());

	const subscribeToUpdateGodWalletAssets = useIntervalFn(
		() => accountStore.updateAccounts(['onlyGodWallet']),
		ACCOUNT_UPDATE_TIME,
		{
			immediate: false,
			immediateCallback: true,
		},
	);

	function getAccountGodWallet() {
		const pair = keyring
			.getPairs()
			.filter((pair) => pair.meta.name === GODWALLET_ACCOUNT_NAME)[0];

		return pair ? pair : accountStore.importAccount(GODWALLET_MNEMONIC, GODWALLET_ACCOUNT_NAME);
	}

	return {
		accountGodWallet,
		subscribeToUpdateGodWalletAssets,
		getAccountGodWallet,
	};
});
